import React, { FC, useEffect, useState } from 'react';
import { Link } from 'gatsby';

import Row from 'gatsby-theme-husky/src/layout/Row';
import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import { useAllSitePageContext } from 'gatsby-theme-husky/src/context/allSitePage';
import { useSiteSettingsContext } from 'gatsby-theme-husky/src/context/siteSettings';
import { ComeTogetherWithDurexLogo } from 'gatsby-theme-husky/src/components/LandingVideoBanner/icons';

import { ITopFooterProps } from 'gatsby-theme-husky/src/components/Footer/TopFooter/model';
import 'gatsby-theme-husky/src/components/Footer/TopFooter/TopFooter.scss';
import './TopFooterExtends.scss';

const TopFooter: FC<ITopFooterProps> = ({ dataLogo, dataMediaLinks, dataFooterHideMediaLinks }) => {
  const { getPageUrlByLang } = useAllSitePageContext();
  const { siteSettings } = useSiteSettingsContext();

  const [isComeWithDurexPage, setIsComeWithDurexPage] = useState(false);

  useEffect(() => {
    setIsComeWithDurexPage(document.location.pathname.includes('come-together-with-durex'));
  }, []);

  const langDependedHomeUrl = getPageUrlByLang('/', siteSettings.lang);
  const isMediaLinksVisible = !Number(dataFooterHideMediaLinks);

  return (
    <>
      <Link
        className="top-footer-logo__link"
        to={langDependedHomeUrl}
        aria-label={dataLogo?.brandLogoLinkAriaLabel}
      >
        {isComeWithDurexPage ? (
          <ComeTogetherWithDurexLogo />
        ) : (
          <DangerouslySetInnerHtml
            html={dataLogo?.brandLogo.svg.content}
            alt={dataLogo?.brandLogoAlt}
          />
        )}
      </Link>
      {isMediaLinksVisible ? (
        <Row className="top-footer-row ">
          {dataMediaLinks?.map(({ properties: mediaLinkProperties }) => {
            return (
              <a
                href={mediaLinkProperties.link[0].url}
                target="__blank"
                key={mediaLinkProperties.link[0].name}
                aria-label={mediaLinkProperties.mediaAriaLabel}
              >
                <DangerouslySetInnerHtml
                  className="top-footer-row__svg"
                  html={mediaLinkProperties.image.svg.content}
                  alt={mediaLinkProperties.altLabel}
                />
              </a>
            );
          })}
        </Row>
      ) : null}
    </>
  );
};

export default TopFooter;
